import { Module } from 'vuex'
import { RootState } from './index'

export interface ImageState {
  image: File | null
  imageName: string
  imageUrl: string
  chartWidth: number
  chartHeight: number
  imageGrid: string[][],
  imageColors: string[]
}

const imageModule: Module<ImageState, RootState> = {
  state: {
    image: null,
    imageName: '',
    imageUrl: '',
    chartWidth: 70,
    chartHeight: 70,
    imageGrid: [],
    imageColors: []
  },
  mutations: {
    setImage (state, image: File) {
      state.image = image
      state.imageName = image.name
      state.imageUrl = URL.createObjectURL(image)
    },
    setChartDimensions (state, { width, height }) {
      state.chartWidth = width
      state.chartHeight = height
    },
    setImageGrid (state, imageGrid) {
      state.imageGrid = imageGrid
    },
    setImageColors (state, imageColors) {
      state.imageColors = imageColors
    }
  },
  actions: {
    selectImage ({ commit }, image: File) {
      commit('setImage', image)
    },
    saveChartDimensions ({ commit }, dimensions) {
      commit('setChartDimensions', dimensions)
    },
    saveImageGrid ({ commit }, imageGrid) {
      commit('setImageGrid', imageGrid)
    },
    saveImageColors ({ commit }, imageColors) {
      commit('setImageColors', imageColors)
    }
  },
  getters: {
    getImage: (state) => state.image,
    getImageDetails: (state) => ({
      imageName: state.imageName,
      imageUrl: state.imageUrl,
      chartWidth: state.chartWidth,
      chartHeight: state.chartHeight
    }),
    getImageGrid: (state) => state.imageGrid,
    getImageColors: (state) => state.imageColors
  }
}

export default imageModule
